<template>
  <section>
    <div class="font-medium text-3xl text-900 mb-3">
      <span class="text-primary text-5xl">/</span> Política de negociação
    </div>
    <div class="text-500 mb-5">Listagem de políticas de negociação</div>

    <div class="card">
      <Toast />
      <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
        <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
          <Chips v-model="filters.filter" placeholder="Pesquisar" @add="loadRecords" @remove="loadRecords" />
        </div>
        <div class="mt-2 ml-auto md:mt-0">
          <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2"
            @click="$router.push('/politica-de-negociacao/adicionar')" />
        </div>
      </div>

      <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack" breakpoint="640px">
        <template #empty> Nenhum registro encontrado. </template>

        <template #loading> Carregando registros. Aguarde ... </template>

        <Column field="id" header="#"></Column>

        <Column field="descricao" header="Descrição"></Column>

        <Column bodyClass="text-right">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-text p-button mr-2" @click="editProduct(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-text p-button mt-2"
              @click="confirmDeleteRecord(slotProps.data)" />
          </template>
        </Column>
      </DataTable>

      <Paginator :rows="perPage" :totalRecords="totalItems" @page="onPage"
        template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
        currentPageReportTemplate="Página {currentPage} de {totalPages}">
      </Paginator>

      <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
          <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="deleteRecord" />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script>
import CrudList from '../../../components/abstract/CrudList.vue'
import CrudService from '@/services/crud'

export default {
  extends: CrudList,
  data() {
    return {
      service: new CrudService('/admin/politica-negociacao'),
    }
  }
};
</script>

<style scoped lang="scss">

</style>
